import {GET_HERO_SLIDES, SET_LOADING} from '../constants/actionTypes'

const initialState = {
    heroSlides: null,
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_HERO_SLIDES:
            return { ...state, heroSlides: action.payload.heroSlides }
        case SET_LOADING:
            return { ...state, loading: !state.loading }
        default:
            return state;
    }
}