import React from 'react';
import {CalendarIconSmall} from "../../assets/img/icons";
import moment from 'moment'
import {Link} from "react-router-dom";

export const BlogItem = ({image, title, content, createdAt, link}) => {
    return (
        <Link to={link} className={'grid md:grid-cols-[320px,1fr] grid-cols-1 transition hover:shadow-md shadow-[#F1BA5150] hover:border-[#F1BA51] border border-opacity-50 rounded-xl overflow-hidden'}>
            <div className={'relative overflow-hidden lg:rounded-l-[15px] lg:rounded-t-none rounded-l-none rounded-t-[15px]'}>
                <div className={'absolute top-0 left-0 bg-[#44002C] bg-opacity-25'}></div>
                <img src={image} loading={'lazy'} className={'w-full h-full object-cover'} alt="blog-image"/>
            </div>
            <div className={'lg:py-9 py-4 lg:px-8 px-4'}>
                <h2 className={'text-xl bold line-clamp-2'}>{title}</h2>
                <p className={'flex items-center gap-6 mt-5'}>
                    <CalendarIconSmall type={'purple'} />
                    <span>{moment(createdAt).format('DD.MM.YYYY')}</span>
                </p>
                <p className={'mt-5 md:line-clamp-[12] line-clamp-4'}>{content}</p>
            </div>
        </Link>
    );
}

export default BlogItem