import React, {useEffect} from 'react'
import {Calendar} from "../components"
import {Helmet} from "react-helmet-async";

const CalendarPage = ({setHeaderStyle}) => {
    useEffect(() => {
        setHeaderStyle('filled')
    }, [])

    return (
        <div>
            <Helmet>
                <title>Theatres Calendar</title>
                <meta name={'description'} content={'Performances Calendar - Hovhannes Abelyan State Drama Theatre'} />
                <link rel="canonical" href="/calendar"/>
            </Helmet>

            <div className={'max-w-[1312px] md:pt-36 pt-[120px] px-4 my-0 mx-auto'}>
                <Calendar />
            </div>
        </div>
    );
};

export default CalendarPage;