import React, {useEffect} from 'react'
import {Navigation, Pagination} from "swiper/modules"
import {Swiper, SwiperSlide} from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/autoplay"
import {useDispatch, useSelector} from "react-redux";
import {getHeroSlides} from "../../actions/heroSlides";
import SliderItem from "./SliderItem";

const Slider = () => {
    const {heroSlides} = useSelector(state => state.heroSlides)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getHeroSlides())
    }, []);

    return (
        <Swiper
            pagination={{clickable: true}}
            loop
            autoplay={{delay: 3000}}
            speed={600}
            modules={[Pagination, Navigation]}
            className={'hero-slider'}
        >
            {heroSlides?.map(heroSlide => <SwiperSlide key={heroSlide._id}><SliderItem {...heroSlide} /></SwiperSlide>)}
        </Swiper>
    );
};

export default Slider;