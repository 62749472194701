import {GET_LEGENDS, SET_LOADING} from '../constants/actionTypes'

const initialState = {
    legends: null,
    loading: false
}

const legendsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LEGENDS:
            return { ...state, legends: action.payload.legends }
        case SET_LOADING:
            return { ...state, loading: !state.loading }
        default:
            return state;
    }
};

export default legendsReducer;