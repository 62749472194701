import {
    CHANGE_LANGUAGE, GET_TRANSLATIONS,
    SET_LOADING,
} from '../constants/actionTypes'

const initialState = {
    translations: null,
    lang: localStorage.getItem('lang') || 'am',
    isLoading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_TRANSLATIONS:
            return { ...state, translations: action.payload }
        case CHANGE_LANGUAGE:
            localStorage.setItem('lang', action.payload)
            return {...state, lang: action.payload}
        case SET_LOADING:
            return { ...state, isLoading: !state.isLoading }
        default:
            return state
    }
}