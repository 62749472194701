import React from 'react';
import {useSelector} from "react-redux";

const LegendsItem = ({image, name}) => {
    const {lang} = useSelector(state => state.translations)

    return (
        <div className="relative slider-image grid grid-rows-[1fr,20px]">
            <div className={'relative w-full overflow-hidden'}>
                <div className={'absolute w-full h-full top-0 left-0 bg-[#44002C] bg-opacity-25'}></div>
                <img src={image} className={'w-full h-full object-cover'} alt="slider-item"/>
            </div>
            <div id={'name'} className={'hidden left-0 text-white md:text-2xl text-lg text-center'}>{name[lang]}</div>
        </div>
    );
};

export default LegendsItem;