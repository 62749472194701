import * as galleryApi from '../api/galery'
import {GET_IMAGES, GET_VIDEOS, SET_LOADING} from '../constants/actionTypes'

export const getImages = (page) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await galleryApi.getGalleryImages(page);

        dispatch({ type: GET_IMAGES, payload: data });
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const getVideos = (page) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await galleryApi.getGalleryVideos(page);

        dispatch({ type: GET_VIDEOS, payload: data });
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}
