import React, {useEffect, useState} from 'react'
import {NavLink, useLocation, useNavigate} from "react-router-dom"
import {ChevronIcon} from "../../assets/img/icons"
import {useSelector} from "react-redux";

const Navbar = ({scrolled, headerStyle, scrollToPremiere, dir}) => {
    const {translations} = useSelector(state => state.translations)

    return (
        <ul className={'flex text-white'}>
            <li onClick={scrollToPremiere}>
                <NavLink
                    to={'/'}
                    className={({isActive}) => (`text-[#F1BA51] bold py-1 lg:px-5 md:px-2 block transition hover:text-[#F1BA51] hover:font-bold`)}
                >{translations?.premiere}</NavLink>
            </li>
            <li>
                <NavLink
                    to={'/performances'}
                    className={({isActive}) => (`${isActive && 'text-[#F1BA51] bold'} py-1 lg:px-5 md:px-2 block transition hover:text-[#F1BA51] hover:font-bold`)}
                >{translations?.theatres}</NavLink>
            </li>
            <li className={`relative group py-1 lg:px-5 md:px-2 rounded-t-md hover:text-[#F1BA51]`}>
                <span className={'flex items-center gap-1 cursor-default group-hover:font-bold'}>{translations?.gallery} <ChevronIcon/></span>
                <div className={'absolute z-50 hidden group-hover:block top-full min-w-full left-0'}>
                    <div
                        className={`transition flex flex-col gap-4 text-base rounded-b-lg px-4 pb-4 mt-5 ${!scrolled && headerStyle !== 'filled' ? dir === '/' && 'bg-[#44002C] bg-opacity-50' : 'bg-[#44002C]'} `}>
                        <NavLink to={'/gallery/images'}
                                 className={'transition text-white hover:text-[#F1BA51] block'}>{translations?.galleryPhotos}</NavLink>
                        <NavLink to={'/gallery/videos'}
                                 className={'transition text-white hover:text-[#F1BA51] block'}>{translations?.galleryVideos}</NavLink>
                    </div>
                </div>
            </li>
            <li className={'relative group py-1 lg:px-5 md:px-2 rounded-t-md hover:text-[#F1BA51]'}>
                <span className={'flex items-center gap-1 cursor-default group-hover:font-bold'}>{translations?.aboutUs} <ChevronIcon/></span>
                <div className={'absolute z-50 hidden group-hover:block top-full w-[140%] left-0'}>
                    <div
                        className={`transition flex flex-col gap-4 text-base rounded-b-lg px-4 pb-4 mt-5 ${!scrolled && headerStyle !== 'filled' ? dir === '/' && 'bg-[#44002C] bg-opacity-50' : 'bg-[#44002C]'} `}>
                        <NavLink to={'/about-us/story'} className={'transition text-white hover:text-[#F1BA51] block'}>{translations?.ourStory}</NavLink>
                        <NavLink to={'/about-us/team'}
                                 className={'transition text-white hover:text-[#F1BA51] block'}>{translations?.ourTeam}</NavLink>
                    </div>
                </div>
            </li>
            <li>
                <NavLink
                    to={'/blog'}
                    className={({isActive}) => (`${isActive && 'text-[#F1BA51] bold'} hover:font-bold py-1 lg:px-5 md:px-2 block transition hover:text-[#F1BA51]`)}
                >{translations?.blog}</NavLink>
            </li>
            <li>
                <NavLink
                    to={'/contacts'}
                    className={({isActive}) => (`${isActive && 'text-[#F1BA51] bold'} hover:font-bold py-1 lg:px-5 md:px-3 block transition hover:text-[#F1BA51]`)}
                >{translations?.contact}</NavLink>
            </li>
        </ul>
    );
};

export default Navbar;