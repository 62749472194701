import {combineReducers} from 'redux'
import blogs from './blog'
import translations from './translations'
import calendar from './calendar'
import premieres from './premieres'
import members from './members'
import legends from "./legends"
import heroSlides from "./heroSlides"
import upcoming from "./upcoming"
import gallery from "./gallery"
import performances from "./performances"

export default combineReducers({
    blogs,
    translations,
    calendar,
    premieres,
    members,
    legends,
    heroSlides,
    upcoming,
    performances,
    gallery
})