import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import PerformanceCard from "./PerformanceCard"
import {changeMonth, getCalendar} from "../../actions/calendar";
import groupMonthsByYear from "../../utils/groupMonthsByYear";
import {ChevronUpIcon, TimeIcon} from "../../assets/img/icons";
import filterPerformanceDays from "../../utils/filterPerformanceDays";
import {Link} from "react-router-dom";

const Calendar = () => {
    const {months, month} = useSelector(state => state.calendar)
    const [weekdaysArray, setWeekdaysArray] = useState(null)
    const [groupedByYear, setGroupedByYear] = useState(null)
    const [datesMobile, setDatesMobile] = useState(null)
    const [openMonths, setOpenMonths] = useState(false)
    const {translations} = useSelector(state => state.translations)
    const dispatch = useDispatch()
    const handleChangeMonth = (monthCode) => {
        dispatch(changeMonth(monthCode))
        toggleOpenMonths()
    }

    const toggleOpenMonths = () => {
        setOpenMonths(!openMonths)
    }

    useEffect(() => {
        dispatch(getCalendar())
    }, [])

    useEffect(() => {
        if(month) {
            setWeekdaysArray(Object.entries(month.calendar))

            setDatesMobile(filterPerformanceDays(Object.entries(month.calendar)))
        }
    }, [month])

    useEffect(() => {
        if (months) {
            const grouped = groupMonthsByYear(months)

            grouped && setGroupedByYear(grouped)
        }
    }, [months])

    return (
        month && (
            <div className='md:pt-7 relative w-full'>
                <div>
                    <div className={'select-none'}>
                        <div className={'flex items-center md:gap-5 gap-2 cursor-pointer md:mt-20 mt-0 mb-[30px] w-fit'} onClick={toggleOpenMonths}>
                            <h1 className={`capitalize font-medium lg:text-5xl md:text-3xl text-lg text-[#44002C]`}>{translations?.months[month.name]} {month.year}</h1>
                            <button className={`${openMonths ? 'rotate-0' : 'rotate-180'} transition duration-300`}>
                                <ChevronUpIcon />
                            </button>
                        </div>
                        <div className={`${openMonths ? 'animate-open-dropdown mb-5' : 'animate-close-dropdown h-0 opacity-0'} transition flex flex-col gap-[30px] overflow-hidden`}>
                            {groupedByYear?.map(year => <div key={year[0]} className={'text-2xl'}>
                                <div className={'flex items-center gap-[30px] overflow-x-scroll disable-scrollbar'}>
                                    <div
                                        className={`${month.year !== Number(year[0]) && 'text-opacity-60'} text-[#44002C]`}>{year[0]}</div>
                                    <div className={'flex items-center gap-5'}>
                                        {year[1].map(m => (
                                            <div
                                                key={m.code}
                                                className={`${month.code !== m.code && 'text-opacity-60'} text-[#44002C] cursor-pointer`}
                                                onClick={() => handleChangeMonth(m)}
                                            >{translations?.months[m.name]}</div>
                                        ))}
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    </div>
                    <div className={`lg:flex w-full hidden justify-between`}>
                        {weekdaysArray?.map((weekDayObj => <div key={weekDayObj[0]}
                                                                className='lg:text-[20px] text-[7px] lg:w-[180px] md:text-left text-center w-12 bg-white mb-5 text-[#393E46] mt-[30px] capitalize'>{translations?.weekdays[weekDayObj[0].toLocaleLowerCase()]}</div>))}
                    </div>
                </div>
                <div className='relative md:mt-8 pb-7'>
                    <div className={`absolute top-0 left-1/2 -translate-x-1/2 transition ${openMonths && 'w-screen h-full bg-[#44002C] bg-opacity-[15%] backdrop-blur-[5px] z-10'}`}></div>

                    <div className={'lg:grid hidden grid-cols-7'}>
                        {weekdaysArray?.map(weekDayObj => (
                            <div key={weekDayObj[0]}>
                                <div>
                                    {weekDayObj[1].map(day => (
                                        day
                                            ?
                                            <div
                                                key={day.day}
                                                className='relative w-full aspect-[1/1.76] border border-[#44002C] border-opacity-20 lg:text-[20px] text-sm leading-[46px] pt-5 pl-4'
                                            >
                                                <span className={'absolute top-0 right-1'}>{day.day}</span>
                                                {day.performance && <PerformanceCard {...day.performance} />}
                                            </div>
                                            :
                                            <div key={`${Math.random() * Math.random()}`}
                                                 className='w-full aspect-[1/1.76]'></div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={'lg:hidden grid md:grid-cols-2 grid-cols-1 gap-2 place-items-center w-full'}>
                        {datesMobile?.length > 0 ? datesMobile.map(date => (
                            <div key={date.day} className={'w-full max-w-[410px] border'}>
                                <div className={'flex items-center justify-between text-[#393E46] p-[10px]'}>
                                    <p className={'capitalize'}>{translations?.weekdays[date.weekday]}</p>
                                    <p>{date.day}</p>
                                </div>
                                <div className={'py-5 px-10'}>
                                    <div>
                                        <img src={date.performance.image} alt="performance image"/>
                                    </div>
                                    <div className={'mt-5'}>
                                        <p>{date.performance.title}</p>
                                        <p className={'flex items-center gap-2 text-sm text-[#393E46]'}>
                                            <TimeIcon/>
                                            <span>{date.performance.time}</span>
                                        </p>
                                        {date.performance.ticketLink && <Link
                                            to={date.performance.ticketLink}
                                            className={'block max-h-[27px] w-fit text-center text-[15px] leading-[17px] py-[5px] px-3 mt-2 bg-[#44002C] text-white rounded-md'}>{translations?.buyTicket}</Link>}
                                    </div>
                                </div>
                            </div>
                        )) : <p>{translations?.noPerformances}</p>}
                    </div>
                </div>
            </div>
        )
    )
};

export default Calendar;