import React from 'react';

const UpcomingTheatersItem = ({image}) => {
    return (
        <div className={'max-w-[460px] aspect-auto-[1,1.42]'}>
            <img src={image} alt="upcoming-theatre-image"/>
        </div>
    );
};

export default UpcomingTheatersItem;