import React from 'react';
import {SupportIcon} from "../assets/img/icons";

const SupportBtn = () => {
    return (
        <button className={'fixed right-2 bottom-[9%] z-[9900] p-2 rounded-xl select-none'}>
            <SupportIcon />
        </button>
    );
};

export default SupportBtn;