import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux"
import thunk from 'redux-thunk'
import {createStore, applyMiddleware, compose} from 'redux'

import reducers from './reducers'
import {HelmetProvider} from "react-helmet-async";

const store = createStore(reducers, compose(applyMiddleware(thunk)))

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <HelmetProvider>
            <BrowserRouter>
                <Provider store={store}>
                    <App/>
                </Provider>
            </BrowserRouter>
        </HelmetProvider>
    </React.StrictMode>
)
