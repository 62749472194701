import React from 'react';
import logo from '../../assets/img/footer/footer-logo.png'
import map from '../../assets/img/footer/map.png'
import bg from '../../assets/img/footer/bg.png'
import {Link} from "react-router-dom";
import {FbIcon, IgIcon, TgIcon, YtIcon} from "../../assets/img/icons";
import {useSelector} from "react-redux";

const Footer = () => {
    const {translations} = useSelector(state => state.translations)

    return (
        <div className={'relative text-white pt-16 pb-24'}>
            <img src={bg} className={'absolute -z-50 w-full h-full top-0 left-0 object-cover'} alt="bg-image"/>

            <div className={'max-w-[1312px] my-0 mx-auto px-4'}>
                <img src={logo} alt="logo"/>
            </div>
            <div
                className={'relative max-w-[1312px] grid md:grid-cols-3 grid-cols-1 px-4 md:mt-0 mt-5 mx-auto md:text-base text-xs'}>
                <div className={'flex flex-col justify-center md:gap-6 gap-4'}>
                    <p>{translations?.address}</p>
                    <p>+374 98 821244</p>
                    <p>abelyanart@mail.ru</p>
                </div>
                <div className={'flex items-center md:justify-center justify-start md:mt-0 mt-4'}>
                    <div className={'flex flex-col md:justify-center justify-start md:gap-6 gap-4'}>
                        <Link to={'/about-us/story'}>{translations?.aboutUs}</Link>
                        <Link to={'/gallery/images'}>{translations?.gallery}</Link>
                        <Link to={'/theatres'}>{translations?.theatres}</Link>
                    </div>
                </div>
                <div className={'md:relative absolute right-0 -bottom-10 flex md:items-center items-end md:justify-center justify-end w-full'}>
                    <Link to={'/contacts'}>
                        <img src={map} className={'md:w-auto w-[165px]'} alt=""/>
                    </Link>
                </div>
            </div>
            <div className={'max-w-[1312px] my-0 mx-auto px-4 flex items-center gap-4 md:justify-start md:mt-[10px] mt-5'}>
                <Link to={'https://www.facebook.com/abelyantheatre/'}>
                    <FbIcon color={'white'}/>
                </Link>
                <Link to={'https://www.instagram.com/abelyan_theatre/'}>
                    <IgIcon color={'white'}/>
                </Link>
                <Link to={'https://www.youtube.com/@abelyantheatre'}>
                    <YtIcon color={'white'}/>
                </Link>
                <Link to={'https://t.me/abelyan_theatre'}>
                    <TgIcon color={'white'}/>
                </Link>
            </div>
        </div>
    );
};

export default Footer;