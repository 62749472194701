export default (months) => {
    const groupedByYear = {}

    months?.forEach(m => {
        groupedByYear[m.year] = groupedByYear[m.year] || []

        groupedByYear[m.year].push(m)
    })

    return Object.entries(groupedByYear)
}