import React, { useEffect } from 'react';
import bg from '../assets/img/contacts/bg.png'
import { FbIcon, IgIcon, MailIcon, MapPinIcon, PhoneIcon, TgIcon, YtIcon } from "../assets/img/icons";
import { Link } from "react-router-dom";
import { YMaps, Map } from "@pbe/react-yandex-maps";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";

const Contacts = ({ setHeaderStyle }) => {
    const { translations } = useSelector(state => state.translations)

    useEffect(() => {
        setHeaderStyle('transparent')
    }, [])

    return (
        <div>
            <Helmet>
                <title>Contacts</title>
                <meta name={'description'} content={'Contacts - Hovhannes Abelyan State Drama Theatre'} />
                <link rel="canonical" href="/contacts" />
            </Helmet>

            <div className={'relative flex items-end w-full md:aspect-[1/0.20] aspect-[1/0.50]'}>
                <div className={'absolute -z-40 top-0 left-0 w-full h-full bg-[#44002C] bg-opacity-60'}></div>
                <img src={bg} className={'absolute -z-50 top-0 left-0 w-full h-full object-cover'} alt="bg-image" />

                <div className={'max-w-7xl px-4 w-full my-0 mx-auto flex justify-start'}>
                    <h1 className={'2xl:pb-[86px] pb-6 lg:text-4xl text-2xl text-white'}>{translations?.feedback}</h1>
                </div>
            </div>
            <div className={'max-w-[1312px] my-0 mx-auto grid lg:grid-cols-[400px,1fr] grid-cols-1 place-items-center lg:gap-2 gap-5 md:pt-[100px] mt-[30px] md:pb-56 px-4 w-full'}>
                <div className={'flex flex-col justify-between h-full'}>
                    <div>
                        <div className={'flex flex-col items-start md:gap-[44px] gap-5'}>
                            <p className={'flex gap-6 items-center'}>
                                <MapPinIcon />
                                <span>{translations?.address}</span>
                            </p>
                            <p className={'flex gap-6 items-center'}><PhoneIcon /> <span>+374 98 821244</span></p>
                            <p className={'flex gap-6 items-center'}><MailIcon /> <span>abelyanart@mail.ru</span></p>
                        </div>
                    </div>
                    <div className={'md:flex hidden flex-col md:justify-start justify-center mt-10'}>
                        <div>{translations?.followUs}</div>
                        <div className={'flex items-center md:justify-start justify-center gap-4 mt-10'}>
                            <Link to={'https://www.facebook.com/abelyantheatre/'}>
                                <FbIcon color={'purple'} />
                            </Link>
                            <Link to={'https://www.instagram.com/abelyan_theatre/'}>
                                <IgIcon color={'purple'} />
                            </Link>
                            <Link to={'https://www.youtube.com/@abelyantheatre'}>
                                <YtIcon color={'purple'} />
                            </Link>
                            <Link to={'https://t.me/abelyan_theatre'}>
                                <TgIcon color={'purple'} />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={'w-full aspect-[1/.56]'}>
                    <YMaps>
                        <Map defaultState={{ center: [40.8095063, 44.4929533], zoom: 15 }} width={'100%'} height={'100%'} />
                    </YMaps>
                </div>
                <div className={'md:hidden flex flex-col md:justify-start justify-end place-self-end mt-5 mb-8'}>
                    <div>{translations?.followUs}</div>
                    <div className={'flex items-center md:justify-start justify-center gap-4 mt-3'}>
                        <Link to={'#'}>
                            <FbIcon color={'purple'} />
                        </Link>
                        <Link to={'#'}>
                            <IgIcon color={'purple'} />
                        </Link>
                        <Link to={'#'}>
                            <YtIcon color={'purple'} />
                        </Link>
                        <Link to={'#'}>
                            <TgIcon color={'purple'} />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;