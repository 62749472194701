import * as calendarApi from '../api/calendar'
import {SET_LOADING, GET_CURRENT_CALENDAR, CHANGE_MONTH} from '../constants/actionTypes'

export const getCalendar = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await calendarApi.getCalendar();

        dispatch({ type: GET_CURRENT_CALENDAR, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const changeMonth = (month) => async (dispatch) => {
    try {
        dispatch({ type: CHANGE_MONTH, payload: month })
    } catch (error) {
        console.log(error.message);
    }
}