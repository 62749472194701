import React, {useEffect} from 'react';
import bg from '../../assets/img/about-us/bg.png'
import building from '../../assets/img/about-us/building.png'
import image from '../../assets/img/about-us/image.png'
import {useSelector} from "react-redux";
import {Helmet} from "react-helmet-async";

const Story = ({setHeaderStyle}) => {
    const {translations} = useSelector(state => state.translations)

    useEffect(() => {
        setHeaderStyle('transparent')
    }, [])

    return (
        <div>
            <Helmet>
                <title>Our Story</title>
                <meta name={'description'} content={'Our Story - Hovhannes Abelyan State Drama Theatre story'} />
                <link rel="canonical" href="/about-us/story"/>
            </Helmet>

            <div className={'relative flex items-end w-full lg:aspect-[1/0.24] aspect-[1/0.50] lg:max-h-[398px] max-h-[200px]'}>
                <div className={'absolute -z-40 top-0 left-0 w-full h-full bg-[#44002C] bg-opacity-25'}></div>
                <img src={bg} className={'absolute -z-50 top-0 left-0 w-full h-full object-cover'} alt="bg-image"/>

                <div className={'max-w-7xl px-4 w-full my-0 mx-auto flex justify-start'}>
                    <h2 className={'xl:pb-[80px] pb-6 lg:text-4xl text-2xl text-white'}>{translations?.ourStory}</h2>
                </div>
            </div>
            <div
                className={'max-w-[1312px] text-justify mx-auto px-4 mt-20 mb-24 md:text-[25px] text-lg leading-[42px]'}>
                <div className={'capitalize text-center md:text-4xl text-2xl mb-[60px] tracking-wide'}>
                    <h2>{translations?.aboutUsTitle}</h2>
                </div>

                <div>
                    <p>{translations?.aboutUsText[0]}</p>
                </div>
                <div className={'mt-[30px] grid lg:grid-cols-[733px,1fr] grid-cols-1 gap-6'}>
                    <div className={'relative'}>
                        <div className={'absolute w-full h-full bg-[#44002C] bg-opacity-25'}></div>
                        <img src={building} className={'w-full h-full'} alt="building-image"/>
                    </div>
                    <div>
                        <p>{translations?.aboutUsText[1]}</p>
                    </div>
                </div>
                <div className={'mt-6 mb-6'}>
                    <p>{translations?.aboutUsText[2]}</p>
                </div>
                <div>
                    <p>{translations?.aboutUsText[3]}</p>
                </div>
                <div className={'flex justify-center py-[30px]'}>
                    <img src={image} alt="image"/>
                </div>
                <div>
                    <p>{translations?.aboutUsText[4]}</p>
                </div>
            </div>
        </div>
    );
};

export default Story;