import {
    GET_UPCOMING_PERFORMANCES,
    SET_LOADING
} from '../constants/actionTypes'
import * as upcomingPerformancesApi from '../api/upcoming'

export const getUpcomingPerformances = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await upcomingPerformancesApi.getUpcomingPerformances()

        dispatch({ type: GET_UPCOMING_PERFORMANCES, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}