import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {getPremieres} from "../../actions/premieres"
import PremiereCard from "./PremiereCard";

const Premiere = () => {
    const {premieres} = useSelector(state => state.premieres)
    const {translations} = useSelector(state => state.translations)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getPremieres())
    }, [])

    if(premieres?.length === 0) return

    return (
        <div className={'max-w-[1312px] my-0 mx-auto px-4 md:pt-24 pt-[60px] pb-24'} >
            <h2 className={'lg:text-5xl text-[22px] text-[#44002C] text-center section-title'}>{translations?.premiere}</h2>

            <div className={'md:mt-[70px] mt-[30px] flex flex-wrap justify-center gap-[12px]'}>
                {premieres?.map(premiere => <PremiereCard key={premiere._id} {...premiere} />)}
            </div>
        </div>
    )
}

export default Premiere;