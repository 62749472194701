import {CHANGE_LANGUAGE, GET_TRANSLATIONS} from "../constants/actionTypes"
import * as translationApi from "../api/translations"

export const getTranslations = (lang) => async (dispatch) => {
    try {
        const {data} = await translationApi.getTranslations(lang)

        dispatch({type: GET_TRANSLATIONS, payload: data})
    } catch (error) {
        console.log(error.message);
    }
}

export const changeLanguage = (lang) => async (dispatch) => {
    try {
        dispatch({type: CHANGE_LANGUAGE, payload: lang})
    } catch (error) {
        console.log(error.message);
    }
}