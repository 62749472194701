import React, {useEffect, useState} from 'react';
import {CalendarIconSmall, TicketIcon} from "../../assets/img/icons";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const TheatreCard = ({title, image, ticketLink, monthIndex, date}) => {
    const {translations} = useSelector(state => state.translations)
    const [monthTranslation, setMonthTranslation] = useState()

    useEffect(() => {
        if(translations) {
            const converted = Object.entries(translations?.months)[monthIndex][1]
            setMonthTranslation(converted)
        }
    }, [translations])

    return (
        <div className={'max-w-[328px] border hover:border-[#F1BA51] hover:border-opacity-50 rounded-[15px] overflow-hidden hover:shadow-lg shadow-[#F1BA51] transition'}>
            <div className={'relative w-full aspect-[1/1.46]'}>
                <img src={image} loading={'lazy'} className={'w-full h-full object-cover'} alt="theatre-image"/>
            </div>
            <div className={'pt-5 pb-6 px-2'}>
                <h2 className={'text-[20px] text-[#393E46] leading-6 bold'}>{title}</h2>
                <div className={'my-[14px]'}>
                    <p className={'flex items-center gap-[10px]'}>
                        <CalendarIconSmall />
                        <span className={'capitalize'}>{monthTranslation} {date}</span>
                    </p>
                </div>
                {ticketLink && <Link to={ticketLink} className={'flex items-center px-[7px] py-[8px] w-fit rounded-[10px] gap-[6px] border border-[#F1BA51]'}>
                    <TicketIcon stroke={'#393E46'} />
                    <span>Գնել տոմս</span>
                </Link>}
            </div>
        </div>
    )
}

export default TheatreCard