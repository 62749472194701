import React from 'react';

const VideoThumbnail = ({thumbnail, embedUrl, title, startPlayer, _id}) => {
    return (
        <div className='w-full'>
            <div className="relative w-full h-full rounded-2xl overflow-hidden" onClick={() => startPlayer(embedUrl)}>
                <img src={thumbnail} className="w-full h-full object-cover" alt="Image" />
                <div className="absolute inset-0 flex items-center justify-center bg-gray-700 bg-opacity-0 cursor-pointer hover:bg-opacity-50 transition">
                    <div className="bg-black bg-opacity-50 p-3 flex items-center justify-center rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#adadad" className="w-12 h-12">
                            <path fillRule="evenodd" d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z" clipRule="evenodd" />
                        </svg>
                    </div>
                </div>
            </div>
            <p className={'line-clamp-2 md:mt-[30px] mt-[10px] md:text-xl text-[15px]'}>{title}</p>
        </div>
    )
};

export default VideoThumbnail;