import {
    GET_UPCOMING_PERFORMANCES,
    SET_LOADING
} from '../constants/actionTypes'

const initialState = {
    upcomingPerformances: null,
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_UPCOMING_PERFORMANCES:
            return { ...state, upcomingPerformances: action.payload.upcomingPerformances }
        case SET_LOADING:
            return { ...state, loading: !state.loading }
        default:
            return state;
    }
}