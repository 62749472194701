import {
    SET_LOADING, GET_CURRENT_CALENDAR, CHANGE_MONTH, GET_PERFORMANCES
} from '../constants/actionTypes'

const initialState = {
    months: null,
    month: null,
    isLoading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_CURRENT_CALENDAR:
            return { ...state, months: action.payload.months, month: action.payload.months[0]}
        case CHANGE_MONTH:
            return { ...state, month: action.payload}
        case SET_LOADING:
            return { ...state, isLoading: !state.isLoading }
        default:
            return state
    }
}