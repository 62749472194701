import React from 'react'
import {TimeIcon} from "../../assets/img/icons"
import {Link} from "react-router-dom"
import {useSelector} from "react-redux";

const PerformanceCard = ({image, title, time, ticketLink}) => {
    const {translations} = useSelector(state => state.translations)

    return (
        <div className={'flex flex-col'}>
            <div className={'w-[81.3%] max-h-[188px]'}>
                <img src={image} className={'w-full h-full object-cover rounded-md'} alt="premiere-image"/>
            </div>
            <div className={'mt-2'}>
                <p className={'text-base line-clamp-1'}>{title}</p>
                <p className={'flex items-center gap-2 text-sm text-[#393E46]'}>
                    <TimeIcon/>
                    <span>{time}</span>
                </p>
                {ticketLink && <Link
                    to={ticketLink}
                    className={'block max-h-[27px] w-fit text-center text-[15px] leading-[17px] py-[5px] px-3 mt-2 bg-[#44002C] text-white rounded-md'}>{translations?.buyTicket}</Link>}
            </div>
        </div>
    )
}

export default PerformanceCard;