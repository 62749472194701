import React, {useEffect, useState} from 'react'
import maskImg from '../assets/img/theatres/mask.png'
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper/modules"
import {SliderControlArrow} from "../assets/img/icons"
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet-async";
import {getPerformances} from "../actions/theatres";
import {TheatreCard} from "../components";

const Performances = ({setHeaderStyle}) => {
    const [slidesPerView, setSlidesPerView] = useState(5)
    const {translations} = useSelector(state => state.translations)
    const {performances} = useSelector(state => state.performances)
    const dispatch = useDispatch()

    useEffect(() => {
        setHeaderStyle('filled')
        dispatch(getPerformances())
    }, [])

    const handleScroll = () => {
        let width = window.innerWidth
        if (width >= 1500) {
            setSlidesPerView(5)
        } else if (width < 1500 && width >= 1200) {
            setSlidesPerView(4)
        } else if (width <= 1200 && width > 900) {
            setSlidesPerView(3)
        } else if (width <= 900 && width > 700) {
            setSlidesPerView(2)
        } else if (width <= 700) {
            setSlidesPerView(1)
        }
    }

    useEffect(() => {
        handleScroll()
        window.addEventListener('resize', handleScroll)
    }, [])

    return (
        <div className={'max-w-[1920px] md:pt-[172px] pt-16 px-4 my-0 mx-auto'}>
            <Helmet>
                <title>Theatres</title>
                <meta name={'description'} content={'Performances - Hovhannes Abelyan State Drama Theatre'} />
                <link rel="canonical" href="/client/src/pages/Performances"/>
            </Helmet>

            <div className={'flex items-center justify-center lg:gap-12 md:gap-8 gap-4 mt-[60px] mb-[60px]'}>
                <img src={maskImg} className={'lg:w-16 md:w-12 w-10'} alt="icon-masks"/>
                <h1 className={'lg:text-6xl text-4xl text-[#44002C]'}>{translations?.theatresPageTitle}</h1>
            </div>

            {performances?.length > 0
                ?
                <div className={"md:block hidden"}>
                    <Swiper
                        slidesPerView={slidesPerView}
                        spaceBetween={30}
                        centeredSlides={false}
                        pagination={{clickable: true}}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                            clickable: true,
                        }}
                        modules={[Pagination, Navigation]}
                        className="theatres-slider"
                    >
                        {performances?.map(theatre => (
                            <SwiperSlide key={theatre._id}>
                                <TheatreCard {...theatre} />
                            </SwiperSlide>
                        ))}

                        <div className="slider-controller">
                            <div className="swiper-button-prev slider-arrow rounded-lg bg-black bg-opacity-50">
                                <SliderControlArrow/>
                            </div>
                            <div
                                className="swiper-button-next slider-arrow rotate-180 rounded-lg bg-black bg-opacity-50">
                                <SliderControlArrow/>
                            </div>
                        </div>
                    </Swiper>
                </div>
                :
                <div className={'flex justify-center my-20 text-xl'}>
                    <h2 className={'text-center text-[#44002C]'}>Խաղացանկը բացակայում է</h2>
                </div>
            }
            <div className={'md:hidden grid grid-cols-1 gap-[30px] place-items-center'}>
                {performances?.map(theatre => <TheatreCard key={theatre._id} {...theatre} />)}
            </div>
        </div>
    );
};

export default Performances;