import React, {useEffect, useState} from 'react'
import bg from '../../assets/img/about-us/bg.png'
import {useDispatch, useSelector} from "react-redux"
import {getMembers} from "../../actions/members"
import {Helmet} from "react-helmet-async";
import {ChevronUpIcon} from "../../assets/img/icons";

const Team = ({setHeaderStyle}) => {
    const {translations, lang} = useSelector(state => state.translations)
    const {members} = useSelector(state => state.members)
    const dispatch = useDispatch()
    const [selectedTeam, setSelectedTeam] = useState(null)
    const [openTeamNames, setOpenTeamNames] = useState(false)

    const teams = [
        {name: translations?.managementTeam, key: 'management'},
        {name: translations?.castTeam, key: 'cast'},
        {name: translations?.creativeTeam, key: 'creative'},
        {name: translations?.serviceTeam, key: 'service'},
    ]

    const toggleOpenTeamNames = () => {
        setOpenTeamNames(!openTeamNames)
    }

    const selectTeam = (team) => {
        toggleOpenTeamNames()
        setSelectedTeam(team)
    }

    useEffect(() => {
        setHeaderStyle('transparent')
        translations && setSelectedTeam(teams[0])
    }, [translations])

    useEffect(() => {
        selectedTeam && dispatch(getMembers(selectedTeam.key))
    }, [selectedTeam])

    const TeamMemberCard = ({avatar, name, position}) => (
        <div className={'w-full flex justify-center'}>
            <div className={'max-w-[300px]'}>
                <div className={'relative w-[296px] h-[296px] rounded-full overflow-hidden'}>
                    <img src={avatar} className={'w-full absolute top-0'} alt="member-image"/>
                </div>
                <div className={'mt-5 text-center'}>
                    <h2 className={'bold mb-5 xl:text-[32px] text-2xl'}>{name[lang]}</h2>
                    <p className={'text-[24px]'}>{translations && translations[position]}</p>
                </div>
            </div>
        </div>
    )

    return (
        <div>
            <Helmet>
                <title>Our Team</title>
                <meta name={'description'} content={'Our Team - Hovhannes Abelyan State Drama Theatre team'}/>
                <link rel="canonical" href="/about-us/team"/>
            </Helmet>

            <div
                className={'relative flex items-end w-full lg:aspect-[1/0.24] aspect-[1/0.50] lg:max-h-[398px] max-h-[200px]'}>
                <div className={'absolute -z-40 top-0 left-0 w-full h-full bg-[#44002C] bg-opacity-25'}></div>
                <img src={bg} className={'absolute -z-50 top-0 left-0 w-full h-full object-cover'} alt="bg-image"/>

                <div className={'max-w-7xl px-4 w-full my-0 mx-auto flex justify-start'}>
                    <h2 className={'xl:pb-[80px] pb-6 lg:text-4xl text-2xl text-white'}>{translations?.ourTeam}</h2>
                </div>
            </div>
            <div className={'max-w-[1312px] text-justify mx-auto px-4 md:mt-20 mt-10 mb-24 text-[25px] leading-[42px]'}>
                <div className={'md:hidden block'}>
                    <div className={'select-none'}>
                        <div className={'flex items-center md:gap-5 gap-2 cursor-pointer md:mt-20 mt-0 mb-[30px] w-fit'}
                             onClick={toggleOpenTeamNames}>
                            <h1 className={`capitalize font-medium lg:text-5xl md:text-3xl text-lg text-[#44002C]`}>{selectedTeam?.name}</h1>
                            <button className={`${openTeamNames ? 'rotate-0' : 'rotate-180'} transition duration-300`}>
                                <ChevronUpIcon/>
                            </button>
                        </div>
                        <div
                            className={`${openTeamNames ? 'animate-open-dropdown mb-5' : 'animate-close-dropdown h-0 opacity-0'} transition flex flex-col gap-[30px] overflow-hidden`}>
                            <div className={'overflow-x-scroll disable-scrollbar'}>
                                {teams.map(team => (
                                    selectedTeam && team.key !== selectedTeam.key && <div key={team.key}
                                                                         className={'lg:text-[20px] text-base text-left bg-white text-[#393E46] mb-6 capitalize'}
                                                                         onClick={() => selectTeam(team)}
                                                                         >{team.name}</div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'md:block hidden max-w-screen overflow-x-scroll md:mb-16 mb-8 disable-scrollbar'}>
                    <div className={'flex md:gap-20 gap-10'}>
                        {selectedTeam && teams?.map(team => (
                            <div key={team.key} onClick={() => selectTeam(team)}
                                 className={`${team.key === selectedTeam.key ? 'text-[#F1BA51]' : 'text-[#44002C]'} flex-none transition md:text-3xl text-xl cursor-pointer whitespace-no-wrap`}>{team.name}</div>
                        ))}
                    </div>
                </div>
                <div>
                    <div
                        className={'grid gap-x-6 gap-y-[70px] place-items-baseline xl:grid-cols-3 md:grid-cols-2 grid-cols-1'}>
                        {members?.map(member => <TeamMemberCard key={member._id} {...member} />)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Team;