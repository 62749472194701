import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import UpcomingTheatersItem from "./UpcomingTheatersItem";
import {getUpcomingPerformances} from "../../actions/upcoming";

const UpcomingTheatres = () => {
    const {translations} = useSelector(state => state.translations)
    const {upcomingPerformances} = useSelector(state => state.upcoming)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getUpcomingPerformances())
    }, []);

    return (
        <div className={'max-w-[1920px] my-0 mx-auto mt-[60px] px-4 pt-26 pb-[200px]'}>
            <h2 className={'lg:text-5xl text-[22px] text-[#44002C] text-center section-title'}>{translations?.upcomingPerformances}</h2>

            <div className={'md:mt-[70px] mt-[30px] grid gap-4 xl:grid-cols-4 grid-cols-2 place-items-center'}>
                {upcomingPerformances?.map(upcomingPerformance => <UpcomingTheatersItem key={upcomingPerformance._id} {...upcomingPerformance} />)}
            </div>
        </div>
    )
}

export default UpcomingTheatres