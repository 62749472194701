import React, {useEffect, useState} from 'react'
import {Footer, Header} from "./components"
import {Route, Routes} from "react-router-dom"
import {AboutUsStory, Blog, Contacts, Main, AboutUsTeam, Calendar, Images, Videos, Performances} from "./pages"
import ScrollToTop from "./components/ScrollToTop";
import SupportBtn from "./components/SupportBtn";
import {useDispatch, useSelector} from "react-redux";
import {getTranslations} from "./actions/translations";

const App = () => {
    const [headerStyle, setHeaderStyle] = useState('filled')
    const {lang} = useSelector(state => state.translations)
    const dispatch = useDispatch()

    useEffect(() => {
        lang ? dispatch(getTranslations(lang)) : dispatch(getTranslations('en'))
    }, [lang]);

    return (
        <div className={'w-screen min-h-screen flex flex-col justify-between'}>
            <Header headerStyle={headerStyle} setHeaderStyle={setHeaderStyle}/>

            <SupportBtn />

            <ScrollToTop />

            <div>
                <Routes>
                    <Route path={'/'} element={<Main setHeaderStyle={setHeaderStyle}/>}/>
                    <Route path={'/contacts'} element={<Contacts setHeaderStyle={setHeaderStyle}/>}/>
                    <Route path={'/blog'} element={<Blog setHeaderStyle={setHeaderStyle}/>}/>
                    <Route path={'/performances'} element={<Performances setHeaderStyle={setHeaderStyle}/>}/>
                    <Route path={'/about-us/story'} element={<AboutUsStory setHeaderStyle={setHeaderStyle}/>}/>
                    <Route path={'/about-us/team'} element={<AboutUsTeam setHeaderStyle={setHeaderStyle}/>}/>
                    <Route path={'/calendar'} element={<Calendar setHeaderStyle={setHeaderStyle}/>}/>
                    <Route path={'/gallery/images'} element={<Images setHeaderStyle={setHeaderStyle}/>}/>
                    <Route path={'/gallery/videos'} element={<Videos setHeaderStyle={setHeaderStyle}/>}/>
                    <Route path={'*'} element={<Main setHeaderStyle={setHeaderStyle}/>} />
                </Routes>
            </div>

            <Footer />
        </div>
    );
};

export default App;