import {
    SET_LOADING,
    GET_IMAGES, GET_VIDEOS
} from '../constants/actionTypes'

const initialState = {
    images: null,
    videos: null,
    isLoading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_IMAGES:
            return { ...state, images: action.payload }
        case GET_VIDEOS:
            return { ...state, videos: action.payload }
        case SET_LOADING:
            return { ...state, isLoading: !state.isLoading }
        default:
            return state
    }
}