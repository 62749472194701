import * as theatresApi from '../api/theatres'
import {GET_PERFORMANCES, SET_LOADING,} from '../constants/actionTypes'

export const getPerformances = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await theatresApi.getTheatres();

        dispatch({ type: GET_PERFORMANCES, payload: data });
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}