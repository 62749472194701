import React, {useEffect} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import {EffectCoverflow, Pagination, Navigation} from 'swiper/modules';
import {SliderControlArrow} from "../../assets/img/icons";

import bg from '../../assets/img/legends/bg.png'
import {useDispatch, useSelector} from "react-redux";
import {getLegends} from "../../actions/legends";
import LegendsItem from "./LegendsItem";

const Legends = () => {
    const {translations} = useSelector(state => state.translations)
    const {legends} = useSelector(state => state.legends)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getLegends())
    }, []);

    const handleChangeSlide = (swiper) => {
        const activeIndex = swiper.activeIndex;

        swiper.slides.forEach((slide, index) => {
            const nameElement = slide.querySelector('#name')
            const zIndex = window.getComputedStyle(slide).getPropertyValue('z-index')

            if (nameElement) {
                if (index === activeIndex) {
                    nameElement.classList.remove('hidden');
                } else {
                    nameElement.classList.add('hidden');
                }

                setTimeout(() => {
                    if(zIndex < -7) {
                        slide.classList.add('opacity-0')
                    } else {
                        slide.classList.remove('opacity-0')
                    }
                }, 20)
            }
        })

    }

    return (
        <div className={'relative legends pt-10 pb-24 px-4 overflow-hidden'}>
            <img src={bg} className={'absolute -z-50 left-0 top-0 w-full h-full object-cover'} alt="bg"/>
            <h2 className={'lg:text-5xl md:text-3xl text-2xl mb-10 text-center text-[#EDECEC]'}>{translations?.ourLegends}</h2>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                loop
                centeredSlides={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 150,
                    modifier: 4,
                }}
                onSlideChange={handleChangeSlide}
                pagination={{el: '.swiper-pagination-slider', clickable: true}}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                    clickable: true,
                }}
                modules={[EffectCoverflow, Pagination, Navigation]}
                className="swiper_legends"
            >
                {legends?.map(legend => (
                    <SwiperSlide key={legend._id}>
                        <LegendsItem {...legend} />
                    </SwiperSlide>
                ))}

                <div className="slider-controller">
                    <div className="swiper-button-prev slider-arrow">
                        <SliderControlArrow/>
                    </div>
                    <div className="swiper-button-next slider-arrow">
                        <SliderControlArrow/>
                    </div>
                </div>
            </Swiper>
        </div>
    )
}

export default Legends