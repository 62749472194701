import * as membersApi from '../api/members'
import {SET_LOADING, GET_MEMBERS} from '../constants/actionTypes'

export const getMembers = (category) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await membersApi.getMembers(category)

        dispatch({ type: GET_MEMBERS, payload: data });
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}
