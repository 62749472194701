import React, {useEffect, useState} from 'react'
import bg from '../../assets/img/gallery/bg.png'
import {Pagination} from "../../components"
import {useDispatch, useSelector} from "react-redux"
import {Helmet} from "react-helmet-async"
import {getImages} from "../../actions/gallery"
import Masonry from "react-masonry-css"

const Images = ({setHeaderStyle}) => {
    const [page, setPage] = useState(1)
    const {translations} = useSelector(state => state.translations)
    const {images} = useSelector(state => state.gallery)
    const dispatch = useDispatch()

    const breakpointColumnsObj = {
        default: 3,
        1100: 2,
        700: 1
    }

    const handleChangePage = (e) => {
        const page = Number(e.target.textContent)
        setPage(page);
        window.scrollTo({top: 0, left: 0})
    }

    useEffect(() => {
        dispatch(getImages(page))
    }, [page])

    useEffect(() => {
        setHeaderStyle('transparent')
    }, [])

    return (
        <div>
            <Helmet>
                <title>Gallery - Images</title>
                <meta name={'description'}
                      content={'Gallery - Images - Hovhannes Abelyan State Drama Theatre gallery'}/>
                <link rel="canonical" href="/gallery/images"/>
            </Helmet>

            <div className={'relative flex items-end w-full md:aspect-[1/0.20] aspect-[1/0.35]'}>
                <div className={'absolute -z-40 top-0 left-0 w-full h-full bg-[#44002C] bg-opacity-60'}></div>
                <img src={bg} className={'absolute -z-50 top-0 left-0 w-full h-full object-cover'} alt="bg-image"/>

                <div className={'max-w-7xl px-4 w-full my-0 mx-auto flex justify-start'}>
                    <h2 className={'2xl:pb-[86px] pb-6 lg:text-4xl md:text-2xl text-lg text-white'}>{translations?.gallery}/ {translations?.galleryPhotos}</h2>
                </div>
            </div>

            <div
                className={'max-w-[1312px] px-4 mx-auto my-0 md:mt-12 mt-8'}>
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="flex w-auto -mx-4"
                    columnClassName="w-full px-4"
                >
                    {images?.data.map(image => (
                        <div
                            key={image._id}
                        >
                            <img
                                src={image.src}
                                alt={`Image ${image.id}`}
                                className="object-cover w-full h-auto rounded-2xl my-4"
                            />
                        </div>
                    ))}
                </Masonry>
            </div>
            <div className={'flex items-center justify-center mt-[120px] mb-[80px] gap-5'}>
                <Pagination count={images?.numberOfPages} page={page} handleChange={handleChangePage}/>
            </div>
        </div>
    )
}

export default Images;