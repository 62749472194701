export default (weekdays) => {
    const days = []

    weekdays.forEach(weekday => {
        weekday[1].forEach(day => {
            if(day && day.performance) {
                day.weekday = weekday[0]
                days.push(day)
            }
        })
    })

    days.sort((a, b) => parseInt(a.day) - parseInt(b.day))

    return days
}