import {GET_PREMIERES, SET_LOADING} from '../constants/actionTypes'

const initialState = {
    premieres: null,
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PREMIERES:
            return {...state, premieres: action.payload.premieres}
        case SET_LOADING:
            return {...state, loading: !state.loading}
        default:
            return state;
    }
}