export const SET_LOADING='SET_LOADING'
export const GET_BLOGS='GET_BLOGS'
export const GET_TRANSLATIONS='GET_TRANSLATIONS'
export const CHANGE_LANGUAGE='CHANGE_LANGUAGE'
export const GET_GALLERY_VIDEOS='GET_GALLERY_VIDEOS'
export const GET_CURRENT_CALENDAR='GET_CURRENT_CALENDAR'
export const CHANGE_MONTH='CHANGE_MONTH'
export const GET_PREMIERES='GET_PREMIERES'
export const GET_MEMBERS='GET_MEMBERS'
export const GET_UPCOMING_PERFORMANCES='GET_UPCOMING_PERFORMANCES'
export const GET_HERO_SLIDES='GET_HERO_SLIDES'
export const GET_LEGENDS='GET_LEGENDS'
export const GET_IMAGES='GET_IMAGES'
export const GET_VIDEOS='GET_VIDEOS'
export const GET_PERFORMANCES='GET_PERFORMANCES'