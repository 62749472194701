import React, {useEffect} from 'react';
import {Legends, Premiere, Slider, UpcomingTheatres} from "../components";
import {Helmet} from "react-helmet-async";

const Main = ({setHeaderStyle}) => {
    useEffect(() => {
        setHeaderStyle('transparent')
    }, [])

    return (
        <>
            <Helmet>
                <title>Main - Abelyan State Drama Theatre</title>
                <meta name={'description'} content={'Main - Hovhannes Abelyan State Drama Theatre | Գլխավոր - Հովհաննես Աբելյանի անվան պետական Դրամատիկական թատրոն | Государственный театр имени Ованнеса Абеляна'} />
                <link rel="canonical" href="/"/>
            </Helmet>

            <Slider />
            <Premiere />
            <UpcomingTheatres />
            <Legends />
        </>
    )
}

export default Main;