import {
    GET_LEGENDS,
    SET_LOADING
} from '../constants/actionTypes'
import * as legendsApi from '../api/legends'
export const getLegends = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await legendsApi.getLegends()

        dispatch({ type: GET_LEGENDS, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}