import {GET_HERO_SLIDES,
    SET_LOADING
} from '../constants/actionTypes'
import * as heroApi from '../api/hero'

export const getHeroSlides = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await heroApi.getHeroSlides()

        dispatch({ type: GET_HERO_SLIDES, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}