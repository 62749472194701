import React from 'react';

const VideoPlayer = ({videoId, close}) => {
    return (
        <div className={'fixed w-full h-full top-0 left-0 bg-[#44002C] bg-opacity-60 z-[9900] px-2 flex items-center justify-center'}>
            <div className={'absolute w-full h-full top-0 left-0'} onClick={close}></div>
            <iframe src={videoId} allowFullScreen className={'w-full aspect-video max-w-xl z-[9999] rounded-lg'}></iframe>
        </div>
    );
};

export default VideoPlayer;