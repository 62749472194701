import * as blogApi from '../api/blog'
import {SET_LOADING, GET_BLOGS} from '../constants/actionTypes'

export const getBlogs = (page) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await blogApi.getBlogs(page);

        dispatch({ type: GET_BLOGS, payload: data });
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}
