import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import BlogItem from "../components/Blog/BlogItem";
import {getBlogs} from "../actions/blog";
import {Pagination} from "../components";
import {Helmet} from "react-helmet-async";

const Blog = ({setHeaderStyle}) => {
    const {blogs, isLoading} = useSelector(state => state.blogs)
    const [page, setPage] = useState(1)
    const {translations} = useSelector(state => state.translations)
    const dispatch = useDispatch()

    const handleChangePage = (e) => {
        const page = Number(e.target.textContent)
        setPage(page);
    }

    useEffect(() => {
        setHeaderStyle('filled')
    }, [])

    useEffect(() => {
        window.scrollTo({top: 0})
        dispatch(getBlogs(page))
    }, [page])

    return (
        <div>
            <Helmet>
                <title>Blog</title>
                <meta name={'description'} content={'Blog - Hovhannes Abelyan State Drama Theatre'}/>
                <link rel="canonical" href="/blog"/>
            </Helmet>

            <div className={'max-w-[1312px] md:pt-52 pt-20 px-4 my-0 mx-auto'}>
                <h1 className={'text-center lg:text-6xl text-4xl text-[#393E46] mt-4 mb-8'}>{translations?.blog}</h1>

                <div className={'grid grid-cols-1 gap-[50px]'}>
                    {blogs?.data.map(blogItem => <BlogItem key={blogItem._id} {...blogItem} />)}
                </div>

                <div className={'flex items-center justify-center mt-[60px] mb-[50px] gap-5'}>
                    <Pagination count={blogs?.numberOfPages} page={page} handleChange={handleChangePage}/>
                </div>
            </div>
        </div>
    );
};

export default Blog;