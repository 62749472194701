import React, {useEffect, useState} from 'react'
import {CalendarIconSmall, TicketIcon} from "../../assets/img/icons"
import {Link} from "react-router-dom"
import {useSelector} from "react-redux"
import moment from "moment"

const PremiereCard = ({title, image, genre, date, ticketLink, monthIndex}) => {
    const {translations} = useSelector(state => state.translations)
    const [monthTranslation, setMonthTranslation] = useState()

    useEffect(() => {
        if(translations) {
            const converted = Object.entries(translations?.months)[monthIndex][1]
            setMonthTranslation(converted)
        }
    }, [translations])

    return (
        <div className={'md:max-w-[310px] max-w-[345px] w-full rounded-xl overflow-hidden'}>
            <div className={'aspect-[1/1.38]'}>
                <img src={image} className={'w-full h-full object-cover'} alt="cover-image"/>
            </div>
            <div className={'flex flex-col gap-6 pt-4 pb-8 px-3 bg-[#77345A] text-white'}>
                <h2 className={'bold leading-5 text-xl'}>{title}</h2>
                <p>{genre}</p>
                <p className={'flex items-center gap-1'}>
                    <CalendarIconSmall type={'white'} />
                    <span>{date} {monthTranslation}</span>
                </p>
                <Link to={ticketLink} className={'flex items-center p-2 w-fit shadow-md rounded-[5px] gap-[6px] bg-[#F1BA51]'}>
                    <TicketIcon stroke={'#fff'} />
                    <span className={'bold'}>{translations?.buyTicket}</span>
                </Link>
            </div>
        </div>
    );
};

export default PremiereCard;