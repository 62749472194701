import React, {useEffect, useState} from 'react'
import bg from '../../assets/img/gallery/bg.png'
import {Pagination} from "../../components"
import {useDispatch, useSelector} from "react-redux"
import VideoThumbnail from "../../components/Gallery/Videos/VideoThumbnail"
import VideoPlayer from "../../components/Gallery/Videos/VideoPlayer"
import {Helmet} from "react-helmet-async"
import {getVideos} from "../../actions/gallery"

const Videos = ({setHeaderStyle}) => {
    const [page, setPage] = useState(1)
    const [player, setPlayer] = useState(null)
    const {translations} = useSelector(state => state.translations)
    const {videos} = useSelector(state => state.gallery)
    const dispatch = useDispatch()

    const handleChangePage = (e) => {
        const page = Number(e.target.textContent)
        window.scrollTo({top: 0, left: 0})
        setPage(page);
    }

    const handleStartPlayer = (id) => {
        setPlayer(id)
    }

    useEffect(() => {
        dispatch(getVideos(page))
    }, [page])

    useEffect(() => {
        setHeaderStyle('transparent')
    }, [])

    useEffect(() => {
        if(player) {
            document.body.overflowY = 'hidden'
        } else {
            document.body.overflowY = 'auto'
        }
    }, [player])

    return (
        <div>
            <Helmet>
                <title>Gallery - Videos</title>
                <meta name={'description'} content={'Gallery - Videos - Hovhannes Abelyan State Drama Theatre gallery'} />
                <link rel="canonical" href="/gallery/videos"/>
            </Helmet>

            {player && <VideoPlayer videoId={player} close={() => setPlayer(null)} />}
            
            <div className={'relative flex items-end w-full md:aspect-[1/0.20] aspect-[1/0.35]'}>
                <div className={'absolute -z-40 top-0 left-0 w-full h-full bg-[#44002C] bg-opacity-60'}></div>
                <img src={bg} className={'absolute -z-50 top-0 left-0 w-full h-full object-cover'} alt="bg-image"/>

                <div className={'max-w-7xl px-4 w-full my-0 mx-auto flex justify-start'}>
                    <h2 className={'2xl:pb-[86px] pb-6 lg:text-4xl md:text-2xl text-lg text-white'}>{translations?.gallery}/ {translations?.galleryVideos}</h2>
                </div>
            </div>

            <div className={'max-w-[1312px] px-4 grid place-items-baseline md:grid-cols-2 grid-cols-1 gap-x-[6%] md:gap-y-[100px] gap-y-[30px] mx-auto my-0 md:mt-12 mt-8'}>
                {videos?.data.map(video => (
                    <VideoThumbnail startPlayer={handleStartPlayer} key={video._id} {...video} />
                ))}
            </div>
            <div className={'flex items-center justify-center mt-[120px] mb-[80px] gap-5'}>
                <Pagination count={videos?.numberOfPages} page={page} handleChange={handleChangePage} />
            </div>
        </div>
    );
};

export default Videos;