import React from 'react';
import {Link, NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {ChevronIcon, CloseIcon, LanguageIcon} from "../../assets/img/icons";

const Menu = ({active, close, scrollToPremiere, handleChangeLanguage, languageActive, languageCodes, setLanguageActive}) => {
    const {translations, lang} = useSelector(state => state.translations)

    const scrollAndClose = () => {
        scrollToPremiere()
        close()
    }

    return (
        <div
            className={`${active ? 'translate-x-0' : '-translate-x-full'} fixed top-0 w-full h-screen z-[9999] bg-[#44002C] flex flex-col py-16 px-4 transition duration-300`}>
            <button className={'absolute top-4 right-4'} onClick={close}>
                <CloseIcon />
            </button>
            <ul className={'text-white flex flex-col gap-[30px]'}>
                <li onClick={scrollAndClose}>
                    <NavLink
                        to={'/'}
                        className={({isActive}) => (`${isActive && 'text-[#F1BA51] bold'} mt-2 py-1 lg:px-5 md:px-2 transition hover:text-[#F1BA51] hover:font-bold`)}
                    >{translations?.premiere}</NavLink>
                </li>
                <li>
                    <NavLink
                        to={'/performances'}
                        onClick={close}
                        className={({isActive}) => (`${isActive && 'text-[#F1BA51] bold'} mt-2 py-1 lg:px-5 md:px-2 transition hover:text-[#F1BA51] hover:font-bold`)}
                    >{translations?.theatres}</NavLink>
                </li>
                <li className={`relative group py-1 lg:px-5 md:px-2 rounded-t-md hover:text-[#F1BA51]`}>
                    <span
                        className={'flex items-center gap-1 cursor-default group-hover:font-bold'}>{translations?.gallery}
                        <ChevronIcon/></span>
                    <div className={'z-50 hidden group-hover:block top-full min-w-full left-0'}>
                        <div
                            className={`transition flex flex-col gap-4 text-base rounded-b-lg px-4 pb-4 mt-5`}>
                            <NavLink to={'/gallery/images'}
                                     onClick={close}
                                     className={'transition text-white hover:text-[#F1BA51]'}>{translations?.galleryPhotos}</NavLink>
                            <NavLink to={'/gallery/videos'}
                                     onClick={close}
                                     className={'transition text-white hover:text-[#F1BA51]'}>{translations?.galleryVideos}</NavLink>
                        </div>
                    </div>
                </li>
                <li className={'relative group py-1 lg:px-5 md:px-2 rounded-t-md hover:text-[#F1BA51]'}>
                    <span
                        className={'flex items-center gap-1 cursor-default group-hover:font-bold'}>{translations?.aboutUs}
                        <ChevronIcon/></span>
                    <div className={'z-50 hidden group-hover:block top-full w-[140%] left-0'}>
                        <div
                            className={`transition flex flex-col gap-4 text-base rounded-b-lg px-4 pb-4 mt-5`}>
                            <NavLink to={'/about-us/story'}
                                     onClick={close}
                                     className={'transition text-white hover:text-[#F1BA51]'}>{translations?.ourStory}</NavLink>
                            <NavLink to={'/about-us/team'}
                                     onClick={close}
                                     className={'transition text-white hover:text-[#F1BA51]'}>{translations?.ourTeam}</NavLink>
                        </div>
                    </div>
                </li>
                <li>
                    <NavLink
                        to={'/blog'}
                        onClick={close}
                        className={({isActive}) => (`${isActive && 'text-[#F1BA51] bold'} mt-2 hover:font-bold py-1 lg:px-5 md:px-2 transition hover:text-[#F1BA51]`)}
                    >{translations?.blog}</NavLink>
                </li>
                <li>
                    <NavLink
                        to={'/contacts'}
                        onClick={close}
                        className={({isActive}) => (`${isActive && 'text-[#F1BA51] bold'} mt-2 hover:font-bold py-1 lg:px-5 md:px-3 transition hover:text-[#F1BA51]`)}
                    >{translations?.contact}</NavLink>
                </li>
            </ul>
        </div>
    );
};

export default Menu;