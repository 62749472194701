import React, {useEffect, useState} from 'react';
import logo from '../../assets/img/logo.png'
import Navbar from "./Navbar";
import {CalendarIcon, LanguageIcon, MenuIcon, SearchIcon} from "../../assets/img/icons";
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {changeLanguage} from "../../actions/translations";
import Menu from "./Menu";

const Header = ({headerStyle}) => {
    const [languageActive, setLanguageActive] = useState(false)
    const [scrolled, setScrolled] = useState(false)
    const [menu, setMenu] = useState(false)
    const {translations, lang} = useSelector(state => state.translations)
    const dispatch = useDispatch()

    const location = useLocation()
    const [dir, setDir] = useState()
    const navigate = useNavigate()

    const languageCodes = [
        {code: 'en', name: "eng"},
        {code: 'am', name: "հայ"},
        {code: 'ru', name: "рус"},
    ]

    const handleChangeLanguage = (e) => {
        dispatch(changeLanguage(e.target.value))
    }

    const handleScroll = () => {
        if (window.scrollY === 0) {
            setScrolled(false)
        } else {
            setScrolled(true)
        }
    }

    const toggleMenu = () => {
        setMenu(prev => !prev)
    }

    const scrollToPremiere = () => {
        const header = document.getElementById('header')
        const headerHeight = header.offsetHeight

        if (location.pathname === '/') {
            window.scrollTo({
                top: window.innerHeight - headerHeight,
                behavior: 'smooth'
            })
        } else {
            navigate('/')
            setTimeout(() => {
                window.scrollTo({
                    top: window.innerHeight - headerHeight,
                    behavior: 'smooth'
                })
            }, 0)
        }
    }

    const scrollTop = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    }, [])

    useEffect(() => {
        setDir(location.pathname)
    }, [location.pathname])

    useEffect(() => {
        setTimeout(() => {
            if (languageActive) {
                setLanguageActive(false)
            }
        }, 2000)
    }, [languageActive])

    return (
        <div
            id={'header'}
            className={`fixed top-0 z-50 w-screen bg-[#44002C] transition ${!scrolled && headerStyle !== 'filled' ? `bg-opacity-0 ${dir === '/' && 'hover:bg-opacity-50'}` : ''} md:py-3 py-1`}>

            <Menu languageCodes={languageCodes} languageActive={languageActive} setLanguageActive={setLanguageActive}
                  handleChangeLanguage={handleChangeLanguage} scrollToPremiere={scrollToPremiere} active={menu}
                  close={toggleMenu}/>

            <div className={'max-w-[1312px] px-4 my-0 mx-auto md:flex md:justify-between grid grid-cols-[40px,1fr]'}>
                <div className={'flex items-center'}>
                    <Link to={'/'}>
                        <img src={logo} onClick={scrollTop} className={'lg:w-[90px] md:w-16 w-7'} alt="logo"/>
                    </Link>
                </div>
                <div className={'min-h-full flex flex-col justify-between items-end py-2'}>
                    <div className={'relative w-full h-full flex items-center justify-end'}>
                        <div
                            className={'md:relative md:top-0 md:transform md:-translate-y-0 md:right-0 flex items-center 2xl:gap-[30px] md:gap-5 gap-2.5 justify-end'}>
                            <NavLink to={'/calendar'}>
                                <CalendarIcon classes={'md:w-[30px] w-[25px] md:h-[30px] h-[25px]'}/>
                            </NavLink>
                            {languageActive ?
                                <div className={'ring-1 ring-[#ffffff50] rounded-lg flex md:p-1 p-0'}>
                                    <div className={'flex gap-4 px-2 text-white'}>
                                        {languageCodes.map(item => (
                                            item.code !== lang && <button key={item.code}
                                                                          className={'transition hover:text-gray-400 capitalize'}
                                                                          onClick={handleChangeLanguage}
                                                                          value={item.code}>{item.name}</button>
                                        ))}
                                    </div>
                                    <button onClick={() => setLanguageActive(!languageActive)}
                                            className={'flex items-center md:p-0 p-1'}>
                                        <LanguageIcon classes={'md:w-[30px] w-[25px] md:h-[30px] h-[25px]'}/>
                                    </button>
                                </div>
                                :
                                <button onClick={() => setLanguageActive(!languageActive)}
                                        className={'flex items-center mr-1'}>
                                    <LanguageIcon classes={'md:w-[32px] w-[25px] md:h-[32px] h-[25px]'}/>
                                </button>}
                        </div>
                        <button onClick={toggleMenu} className={'md:hidden block ml-2.5'}>
                            <MenuIcon/>
                        </button>
                        <Link to={'https://www.tkt.am/hy/vanadzor/cid/3'}
                              className={'md:block hidden 2xl:ml-[30px] ml-4 py-[12px] px-5 text-base bg-[#F1BA51] rounded-lg bold text-white'}>
                            {translations?.buyTicket}
                        </Link>
                    </div>
                    <div className={'mt-5 md:block hidden'}>
                        <Navbar scrollToPremiere={scrollToPremiere} scrolled={scrolled} headerStyle={headerStyle} dir={dir}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;