import React from 'react'
import {Link} from "react-router-dom"
import {useSelector} from "react-redux";

const SliderItem = ({image, title, link}) => {
    const {translations, lang} = useSelector(state => state.translations)

    return (
        <div className={'relative w-screen h-screen'}>
            <div className={'absolute z-40 top-0 left-0 w-screen h-screen bg-[#44002C40]'}></div>
            <img src={image} className={'absolute w-full h-full object-cover object-top'} alt="bg-image"/>

            <div className={'absolute z-50 max-w-7xl w-full my-0 mx-auto bottom-[25%] left-1/2 transform -translate-x-1/2'}>
                <div className={'max-w-[750px] px-4'}>
                    <h1 className={'bold lg:text-[32px] text-xl lg:leading-[44px] text-white mb-[80px]'}>
                        {title[lang]}
                    </h1>
                    <Link to={link}
                          className={'md:block hidden w-fit py-5 px-[30px] bg-[rgba(241,186,81,1)] rounded-lg bold text-white text-xl leading-[24px]'}>{translations?.playlist}</Link>
                    <Link to={'https://www.tkt.am/hy/vanadzor/cid/3'}
                          className={'md:hidden block w-fit py-[12px] px-5 text-base bg-[#F1BA51] rounded-lg bold text-white'}>
                        {translations?.buyTicket}
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default SliderItem