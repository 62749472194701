import {
    SET_LOADING, GET_MEMBERS
} from '../constants/actionTypes'

const initialState = {
    members: null,
    isLoading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_MEMBERS:
            return { ...state, members: action.payload.members }
        case SET_LOADING:
            return { ...state, isLoading: !state.isLoading }
        default:
            return state
    }
}